.bg-svt {
  background-color: rgb(27, 22, 20);
}

.bg-article-card, .bg-video-card {
  background-color: rgb(33, 29, 27);
}

.w-narrow-sidebar {
  width: 4.5rem !important;
}

.w-narrow-sidebar-icon {
  font-size: 1.8rem;
}

.slick-dots li button:before {
  color: white !important;
}

.slick-track {
  margin-left: inherit !important;
  margin-right: inherit !important;
}